import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import * as domainObjectActions from 'src/redux/domainObject/actions';
import { getDomainObjectTags } from 'src/sagas/api/tagr';
import { DomainObject, Tag } from 'src/utils/types';

export function* setActiveDomainWithTags(domainObject: DomainObject): SagaIterator<void> {
    const tags: Tag[] = yield call(getDomainObjectTags, domainObject.domainType, domainObject.id);

    yield put(domainObjectActions.setActiveDomainObject({ ...domainObject, tags }));
}
