/* eslint @typescript-eslint/explicit-function-return-type: 0 */

import { createAction } from 'typesafe-actions';

import { ErrorMessage } from 'src/redux/tag/types';
import { ActionTaken, DomainObject, Tag } from 'src/utils/types';

export const createTag = createAction('NAVIGATION_CREATE_TAG');
export const confirmation = createAction(
    'NAVIGATION_CONFIRMATION',
    resolve => (actionTaken: ActionTaken, tag: Tag) => resolve({ actionTaken, tag })
);
export const domainObjectView = createAction(
    'NAVIGATION_DOMAIN_OBJECT_VIEW',
    resolve => (domainObject: DomainObject) => resolve({ domainObject })
);

export const bulkTag = createAction('NAVIGATION_BULK_TAG', resolve => (tag: Tag) => resolve({ tag }));
export const editTag = createAction(
    'NAVIGATION_EDIT_TAG',
    resolve => (tag: Tag, errorMessage: ErrorMessage | null = null) => resolve({ tag, errorMessage })
);

export const search = createAction('NAVIGATION_SEARCH');
export const waiting = createAction('NAVIGATION_WAITING');
