import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';

import { BusinessTable } from 'src/components/search/BusinessTable';
import { State } from 'src/redux/state.types';
import { getActiveTag, getErrorMessage } from 'src/redux/tag/selectors';
import { ErrorMessage } from 'src/redux/tag/types';
import { ActionTaken, Tag } from 'src/utils/types';

import { TagPage } from './styles';
import { TagForm } from './TagForm';

interface EditStateProps {
    errorMessage: ErrorMessage | null;
    tag: Tag | null;
}

export type EditComponentProps = EditStateProps;

export class EditComponent extends React.Component<EditComponentProps> {
    public constructor(props: EditComponentProps) {
        super(props);
    }

    render() {
        if (!this.props.tag) {
            return <></>;
        }

        return (
            <div>
                <TagPage>
                    <h1>Modify Tag</h1>
                    {this.props.errorMessage ? <Alert variant="warning">{this.props.errorMessage}</Alert> : null}
                    <TagForm existingTag={this.props.tag} onSubmitAction={ActionTaken.MODIFIED} />
                </TagPage>
                <BusinessTable resultsPerPage={5} />
            </div>
        );
    }
}

export const mapStateToProps: (state: State) => EditStateProps = (state: State) => ({
    errorMessage: getErrorMessage(state),
    tag: getActiveTag(state)
});

export const Edit = connect(mapStateToProps)(EditComponent);
