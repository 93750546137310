export const config = {
    // The webapp config is assigned using information from tagr server.
    // (see _setWebappConfig in sagas/api/index.ts)
    WEBAPP_CONFIG: {
        API_DOMAIN: '',
        API_NAMESPACE: '',
        API_PORT: '',
        API_SCHEME: '',
        API_SERVICE_NAME: '',
        AUTH_SUBDOMAIN: '',
        EDGE_SUBDOMAIN: ''
    },
    TAGR_SERVER_NAME_OVERRIDE: process.env.REACT_APP_TAGR_SERVER_NAME_OVERRIDE
};
