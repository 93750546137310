import Cookies from 'js-cookie';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { history } from 'src/components/routes/Routes';
import * as apiActions from 'src/redux/api/actions';
import * as loginActions from 'src/redux/login/actions';
import * as navigationActions from 'src/redux/navigation/actions';
import * as searchActions from 'src/redux/search/actions';
import * as tagActions from 'src/redux/tag/actions';
import { EDGE_TOKEN_NAME } from 'src/sagas/api';
import { setActiveDomainWithTags } from 'src/sagas/domain';
import { DomainType, Path } from 'src/utils/types';

export function* watcher(): SagaIterator<void> {
    yield all([
        takeLatest(apiActions.error, _goToOutOfService),
        takeLatest(navigationActions.createTag, _goToCreateTag),
        takeLatest(navigationActions.confirmation, _goToConfirmation),
        takeLatest(navigationActions.domainObjectView, _goToDomainObjectView),
        takeLatest(navigationActions.bulkTag, _goToBulkTag),
        takeLatest(navigationActions.editTag, _goToEditTag),
        takeLatest([loginActions.authenticated, navigationActions.search], _goToSearch),
        takeLatest(loginActions.logout, _goToLogout),
        takeLatest(navigationActions.waiting, _goToWaiting)
    ]);
}

export const _goToOutOfService = (): void => {
    history.push(Path.OUT_OF_SERVICE);
};

export const _goToCreateTag = (): void => {
    history.push(Path.CREATE_TAG);
};

export function* _goToConfirmation(action: ActionType<typeof navigationActions.confirmation>): SagaIterator<void> {
    yield put(tagActions.setActionTaken(action.payload.actionTaken));
    yield put(tagActions.setActiveTag(action.payload.tag));
    history.push(Path.CONFIRMATION);
}

export function* _goToDomainObjectView(
    action: ActionType<typeof navigationActions.domainObjectView>
): SagaIterator<void> {
    yield call(setActiveDomainWithTags, action.payload.domainObject);
    history.push(Path.DOMAIN_VIEW);
}

export function* _goToBulkTag(action: ActionType<typeof navigationActions.bulkTag>): SagaIterator<void> {
    yield put(tagActions.setActiveTag(action.payload.tag));
    history.push(Path.BULK_TAG);
}

export function* _goToEditTag(action: ActionType<typeof navigationActions.editTag>): SagaIterator<void> {
    yield put(searchActions.clearSearch());
    yield put(apiActions.searchDomainObjectsByTag(DomainType.BUSINESS, action.payload.tag));
    yield put(tagActions.setActiveTag(action.payload.tag, action.payload.errorMessage));
    history.push(Path.EDIT_TAG);
}

export function* _goToSearch(): SagaIterator<void> {
    yield put(searchActions.clearSearch());
    yield put(tagActions.setActiveTag(null));
    history.push(Path.SEARCH);
}

export const _goToLogout = (): void => {
    // This probably deserves a seperate saga file
    Cookies.remove(EDGE_TOKEN_NAME);
    window.localStorage.removeItem('email');
    history.push(Path.LOGIN);
};

export const _goToWaiting = (): void => {
    history.push(Path.WAITING);
};
