import styled from 'styled-components';

export const NavBar = styled.div`
    background-color: #e6def2;
    display: flex;
    justify-content: space-between;
    padding: 1em;
`;

export const NavBarLeft = styled.div``;

export const NavBarRight = styled.div``;
