import * as React from 'react';
import { Alert, Button, Form, FormControlProps } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as apiActions from 'src/redux/api/actions';
import { ActionTaken, Tag } from 'src/utils/types';

const TAG_NAME_PATTERN = /^[a-z][a-z0-9:-]*$/;
const tagNameRegex = new RegExp(TAG_NAME_PATTERN);

interface TagFormProps {
    existingTag?: Tag;
    onSubmitAction: ActionTaken;
}

interface TagFormDispatchProps {
    addTag: (tag: Tag) => void;
    deleteTag: (tag: Tag) => void;
    updateTag: (tag: Tag) => void;
}

export type TagFormComponentProps = TagFormProps & TagFormDispatchProps;

interface TagFormState {
    hasTagNameError: boolean;
    tag: Tag;
}

export class TagFormComponent extends React.Component<TagFormComponentProps, TagFormState> {
    public constructor(props: TagFormComponentProps) {
        super(props);

        const defaultTag: Tag = {
            description: '',
            id: 0,
            name: ''
        };
        this.state = {
            hasTagNameError: false,
            tag: props.existingTag ? props.existingTag : defaultTag
        };
    }

    validateForm() {
        return this.state.tag.name;
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState: TagFormState = { ...this.state, hasTagNameError: false };

        switch (event.target.id) {
            case 'name':
                {
                    const tagName = event.target.value.trim();
                    if (tagName.length && !tagNameRegex.test(tagName)) {
                        this.setState({
                            hasTagNameError: true
                        });
                        return;
                    }
                    newState.tag.name = tagName;
                }
                break;
            case 'description':
                newState.tag.description = event.target.value;
                break;
        }
        this.setState(newState);
    };

    handleSubmit = (event: React.FormEvent<FormControlProps>) => {
        event.preventDefault();

        switch (this.props.onSubmitAction) {
            case ActionTaken.CREATED:
                this.props.addTag(this.state.tag);
                break;
            case ActionTaken.MODIFIED:
            default:
                this.props.updateTag(this.state.tag);
        }
    };

    handleDelete = () => {
        this.props.deleteTag(this.state.tag);
    };

    private renderDeleteButton = (): JSX.Element => {
        if (!this.props.existingTag) {
            return <></>;
        }

        return (
            <Button variant="link" size="sm" onClick={this.handleDelete}>
                Delete Tag
            </Button>
        );
    };

    private hideTagNameError = () => {
        this.setState({
            hasTagNameError: false
        });
    };

    private renderTagNameError = (): JSX.Element => {
        if (!this.state.hasTagNameError) {
            return <></>;
        }

        return (
            <div>
                <Alert dismissible={true} onClose={this.hideTagNameError} variant={'danger'}>
                    A tag name must start with a lowercase letter &quot;a-z&quot;, and then can only include
                    lowercase letters &quot;a-z&quot;, digits &quot;0-9&quot;, colons &quot;:&quot;, and dashes
                    &quot;-&quot; (ex., tags-are:great100)
                </Alert>
            </div>
        );
    };

    render() {
        return (
            <>
                {this.renderTagNameError()}
                <Form onSubmit={this.handleSubmit} autoComplete="off">
                    <Form.Group controlId="name">
                        <Form.Label className="m-1">Name</Form.Label>
                        <Form.Control
                            autoFocus
                            onChange={this.handleChange as any}
                            size="sm"
                            type="name"
                            value={this.state.tag.name}
                        />
                    </Form.Group>

                    <Form.Group controlId="description">
                        <Form.Label className="m-1">Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={this.handleChange as any}
                            size="sm"
                            type="description"
                            value={this.state.tag.description}
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit" size="sm" disabled={!this.validateForm()}>
                        Submit
                    </Button>
                    {this.renderDeleteButton()}
                </Form>
            </>
        );
    }
}

export const mapDispatchToProps = (dispatch: Function) => ({
    addTag: (tag: Tag) => dispatch(apiActions.addTag(tag)),
    deleteTag: (tag: Tag) => dispatch(apiActions.deleteTag(tag)),
    updateTag: (tag: Tag) => dispatch(apiActions.updateTag(tag))
});

export const TagForm = connect(null, mapDispatchToProps)(TagFormComponent);
