import * as React from 'react';
import { Badge, Button, Form, FormControlProps } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as apiActions from 'src/redux/api/actions';
import * as navigationActions from 'src/redux/navigation/actions';
import { State } from 'src/redux/state.types';
import { getActiveTag } from 'src/redux/tag/selectors';
// import { ErrorMessage } from 'src/redux/tag/types';
import { DomainType, Tag } from 'src/utils/types';

enum TagAction {
    TAG = 'tag',
    UNTAG = 'untag'
}

export type BulkState = {
    action: TagAction;
    file: File | null;
};

export type BulkStateProps = {
    tag: Tag | null;
};

export type BulkDispatchProps = {
    addDomainObjectToTagBulk(tag: Tag, domainType: DomainType, file: File): void;
    deleteDomainObjectToTagBulk(tag: Tag, domainType: DomainType, file: File): void;
    goToSearch(): void;
};

export type BulkComponentProps = BulkStateProps & BulkDispatchProps;

export class BulkComponent extends React.Component<BulkComponentProps, BulkState> {
    public constructor(props: BulkComponentProps) {
        super(props);
        this.state = { action: TagAction.TAG, file: null };
    }

    public componentDidMount() {
        if (!this.props.tag) {
            this.props.goToSearch();
        }
    }

    private handleActionSelection(event: React.ChangeEvent<HTMLInputElement>) {
        if (!Object.values(TagAction).includes(event.target.value as TagAction)) {
            return;
        }

        this.setState({
            action: event.target.value as TagAction
        });
    }

    private handleFileSelection(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.files || !event.target.files.length) {
            return;
        }

        this.setState({
            file: event.target.files[0]
        });
    }

    private handleSubmit(event: React.FormEvent<FormControlProps>) {
        event.preventDefault();

        if (!this.props.tag) {
            return;
        }
        if (!this.state.file) {
            return;
        }

        if (this.state.action === TagAction.TAG) {
            this.props.addDomainObjectToTagBulk(this.props.tag, DomainType.BUSINESS, this.state.file);
        } else if (this.state.action === TagAction.UNTAG) {
            this.props.deleteDomainObjectToTagBulk(this.props.tag, DomainType.BUSINESS, this.state.file);
        }
    }

    render() {
        if (!this.props.tag) {
            return <></>;
        }

        return (
            <div>
                <Form onSubmit={this.handleSubmit.bind(this)} autoComplete="off">
                    <Form.Group>
                        Bulk&nbsp;
                        <Form.Check
                            inline
                            name="action"
                            type="radio"
                            value={TagAction.TAG}
                            label="Tag"
                            checked={this.state.action === TagAction.TAG}
                            onChange={this.handleActionSelection.bind(this)}
                        />
                        <Form.Check
                            inline
                            name="action"
                            type="radio"
                            value={TagAction.UNTAG}
                            label="Un-Tag"
                            checked={this.state.action === TagAction.UNTAG}
                            onChange={this.handleActionSelection.bind(this)}
                        />
                        <Badge key={this.props.tag.id} className="m-1" variant="dark">
                            {this.props.tag.name}
                        </Badge>
                    </Form.Group>
                    <Form.Control type="file" onChange={this.handleFileSelection.bind(this)} />
                    <br />
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </div>
        );
    }
}

export const mapStateToProps = (state: State): BulkStateProps => ({
    tag: getActiveTag(state)
});

export const mapDispatchToProps = (dispatch: Function): BulkDispatchProps => ({
    addDomainObjectToTagBulk: (tag: Tag, domainType: DomainType, file: File) =>
        dispatch(apiActions.addDomainObjectToTagBulk(tag, domainType, file)),
    deleteDomainObjectToTagBulk: (tag: Tag, domainType: DomainType, file: File) =>
        dispatch(apiActions.deleteDomainObjectToTagBulk(tag, domainType, file)),
    goToSearch: () => dispatch(navigationActions.search())
});

export const Bulk = connect(mapStateToProps, mapDispatchToProps)(BulkComponent);
