import { getType } from 'typesafe-actions';

import * as domainObjectActions from './actions';
import { DomainObjectActions, DomainObjectState } from './types';

const INITIAL_STATE: DomainObjectState = {
    activeDomainObject: null
};

export const domainObjectReducer = (
    state: DomainObjectState = INITIAL_STATE,
    action: DomainObjectActions
): DomainObjectState => {
    switch (action.type) {
        case getType(domainObjectActions.setActiveDomainObject):
            return {
                ...state,
                activeDomainObject: action.payload.domainObject
            };
        default:
            return state;
    }
};
