import * as React from 'react';
import { Badge, Button, Form, FormControlProps } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as apiActions from 'src/redux/api/actions';
import * as navigationActions from 'src/redux/navigation/actions';
import { getTags } from 'src/redux/search/selectors';
import { State } from 'src/redux/state.types';
import { DomainObject, DomainType, getDomainTypeDisplayName, Tag } from 'src/utils/types';

interface DomainTagsProps {
    domainObject: DomainObject;
}

interface DomainObjectTagsStateProps {
    tags: Tag[];
}

interface DomainObjectTagsDispatchProps {
    addDomainObjectToTag: (tag: Tag, domainType: DomainType, domainObjectId: number) => void;
    deleteDomainObjectToTag: (tag: Tag, domainType: DomainType, domainObjectId: number) => void;
    goToEditTag: (tag: Tag) => void;
    searchTags: (tagNamePrefix: string) => void;
}

export type DomainObjectTagsComponentProps = DomainTagsProps &
    DomainObjectTagsStateProps &
    DomainObjectTagsDispatchProps;

interface DomainObjectTagsState {
    tagNamePrefix: string;
}

export class DomainObjectTagsComponent extends React.Component<
    DomainObjectTagsComponentProps,
    DomainObjectTagsState
> {
    public constructor(props: DomainObjectTagsComponentProps) {
        super(props);
        this.state = {
            tagNamePrefix: ''
        };
    }

    private getExistingTags = (): Tag[] => {
        if (!this.props.domainObject.tags) {
            return [];
        }

        return this.props.domainObject.tags;
    };

    private implicitlyAddTag = () => {
        if (this.props.tags.length !== 1) {
            return;
        }

        const tag = this.props.tags[0];

        if (this.state.tagNamePrefix !== tag.name) {
            return;
        }

        const existingTagIds = this.getExistingTags().map(tag => tag.id);

        if (!existingTagIds.includes(tag.id)) {
            this.props.addDomainObjectToTag(tag, this.props.domainObject.domainType, this.props.domainObject.id);
        }
    };

    private handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState: DomainObjectTagsState = this.state;

        switch (event.target.id) {
            case 'searchTags':
                newState.tagNamePrefix = event.target.value;
                break;
        }
        this.setState(newState);
    };

    private validateForm = (): boolean => {
        return !!this.state.tagNamePrefix;
    };

    private handleSubmit = (event: React.FormEvent<FormControlProps>) => {
        event.preventDefault();
        this.props.searchTags(this.state.tagNamePrefix);
    };

    private handleTagAddClick = (tag: Tag) => {
        this.props.addDomainObjectToTag(tag, this.props.domainObject.domainType, this.props.domainObject.id);
    };

    private handleTagDeleteClick = (tag: Tag) => {
        this.props.deleteDomainObjectToTag(tag, this.props.domainObject.domainType, this.props.domainObject.id);
    };

    private renderExistingTags = () => {
        if (!this.props.domainObject.tags) {
            return <small className="text-muted mb-1">Loading...</small>;
        }

        if (this.props.domainObject.tags.length === 0) {
            return <small className="text-muted mb-1">No existing tags</small>;
        }

        return this.props.domainObject.tags.map(tag => {
            return (
                <Badge key={tag.id} className="m-1" variant="dark" onClick={() => this.props.goToEditTag(tag)}>
                    {tag.name} {}
                    <Button
                        className="close-button p-0 m-0"
                        variant="outline-light"
                        size="sm"
                        onClick={(event: any) => {
                            event.stopPropagation(); // prevent the parent (badge) onclick from processing
                            this.handleTagDeleteClick(tag);
                        }}
                    >
                        x
                    </Button>
                </Badge>
            );
        });
    };

    private renderSearchTags = () => {
        if (!this.props.tags || this.props.tags.length === 0) {
            return <small className="text-muted">No tags found</small>;
        }

        const existingTagIds = this.getExistingTags().map(tag => tag.id);

        return this.props.tags.map(tag => {
            if (!existingTagIds.includes(tag.id)) {
                return (
                    <Button
                        className="close-button m-1"
                        variant="info"
                        size="sm"
                        onClick={() => {
                            this.handleTagAddClick(tag);
                        }}
                    >
                        {tag.name}
                    </Button>
                );
            } else {
                return <></>;
            }
        });
    };

    private renderLink = () => {
        if (this.props.domainObject.domainType !== DomainType.BUSINESS) {
            return <></>;
        }
        return (
            <Button
                className="link-button float-right"
                size="sm"
                variant="secondary"
                href={`https://mc.fivestars.com/business/${this.props.domainObject.primaryKey}`}
            >
                Mission Control
            </Button>
        );
    };

    private renderSubtitle = () => {
        return (
            <h4 className="text-muted">
                {getDomainTypeDisplayName(this.props.domainObject.domainType)} | {this.props.domainObject.id}
            </h4>
        );
    };

    render() {
        this.implicitlyAddTag();

        return (
            <div className="p-2">
                {this.renderLink()}
                <div className="m-1">
                    <h1>{this.props.domainObject.primaryKey}</h1>
                    <br />
                    {this.renderSubtitle()}
                    <br />
                    {this.renderExistingTags()}
                </div>
                <br />
                <Form onSubmit={this.handleSubmit} autoComplete="off">
                    <Form.Group controlId="searchTags">
                        <Form.Control
                            autoFocus
                            onChange={this.handleFormChange}
                            size="sm"
                            type="searchTags"
                            value={this.state.tagNamePrefix}
                            placeholder={'Enter the first few characters for the tag you would like to add'}
                        />
                    </Form.Group>
                    <Button
                        className="add-tag-button"
                        variant="primary"
                        size="sm"
                        type="submit"
                        disabled={!this.validateForm()}
                    >
                        Submit
                    </Button>
                </Form>
                <br />
                {this.renderSearchTags()}
            </div>
        );
    }
}

export const mapStateToProps: (state: State) => DomainObjectTagsStateProps = (state: State) => ({
    tags: getTags(state)
});

export const mapDispatchToProps = (dispatch: Function) => ({
    addDomainObjectToTag: (tag: Tag, domainType: DomainType, domainObjectId: number) =>
        dispatch(apiActions.addDomainObjectToTag(tag, domainType, domainObjectId)),
    deleteDomainObjectToTag: (tag: Tag, domainType: DomainType, domainObjectId: number) =>
        dispatch(apiActions.deleteDomainObjectToTag(tag, domainType, domainObjectId)),
    goToEditTag: (tag: Tag) => dispatch(navigationActions.editTag(tag)),
    searchTags: (tagNamePrefix: string) => dispatch(apiActions.searchTags(tagNamePrefix))
});

export const DomainObjectTags = connect(mapStateToProps, mapDispatchToProps)(DomainObjectTagsComponent);
