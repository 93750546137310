import * as React from 'react';
import { Button, Col, Form, FormControlProps, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as apiActions from 'src/redux/api/actions';
import * as navigationActions from 'src/redux/navigation/actions';
import * as searchActions from 'src/redux/search/actions';
import { getQuery, getSearchType, getTags } from 'src/redux/search/selectors';
import { SearchType } from 'src/redux/search/types';
import { State } from 'src/redux/state.types';
import * as tagActions from 'src/redux/tag/actions';
import { getActiveTag } from 'src/redux/tag/selectors';
import { DomainType, Tag, TAG } from 'src/utils/types';

import { SearchBarForm } from './styles';

interface SearchBarParentProps {
    clearSearch: () => void;
}

interface SearchBarStateProps {
    activeTag: Tag | null;
    query: string;
    searchType: SearchType;
    tags: Tag[];
}

interface SearchBarDispatchProps {
    bulkTag: (tag: Tag) => void;
    editTag: (tag: Tag) => void;
    searchDomainObjects: (domainType: DomainType, keyword: string) => void;
    searchDomainObjectsByTag: (domainType: DomainType, tag: Tag) => void;
    searchTags: (tagNamePrefix: string) => void;
    setActivePageNumber: (activePageNumber: number) => void;
    setActiveTag: (tag: Tag) => void;
}

export type SearchBarComponentProps = SearchBarDispatchProps & SearchBarParentProps & SearchBarStateProps;

enum PlaceholderText {
    BUSINESS = 'Any part of the SOFTWARE_ID',
    BUSINESS_GROUP = 'Any part of the Business Group Name',
    TAG = 'Prefix of Tag Name(s)'
}

interface SearchBarState {
    query: string;
    searchType: SearchType;
}

export class SearchBarComponent extends React.Component<SearchBarComponentProps, SearchBarState> {
    public constructor(props: SearchBarComponentProps) {
        super(props);
        this.state = {
            query: this.props.query,
            searchType: this.props.searchType
        };
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState: SearchBarState = this.state;

        switch (event.target.id) {
            case 'query':
                newState.query = event.target.value;
                break;
        }
        this.setState(newState);
    };

    private handleSubmit = (event: React.FormEvent<FormControlProps>) => {
        event.preventDefault();

        if (this.state.searchType === DomainType.BUSINESS || this.state.searchType === DomainType.BUSINESS_GROUP) {
            this.props.searchDomainObjects(this.state.searchType, this.state.query);
        }

        if (this.state.searchType === TAG) {
            this.props.searchTags(this.state.query);
        }

        this.props.setActivePageNumber(1);
    };

    private validateForm = () => {
        return this.state.query.length > 0;
    };

    private handleSearchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.clearSearch();

        switch (event.target.value) {
            case DomainType.BUSINESS:
                this.setState({
                    query: '',
                    searchType: DomainType.BUSINESS
                });
                break;

            case DomainType.BUSINESS_GROUP:
                this.setState({
                    query: '',
                    searchType: DomainType.BUSINESS_GROUP
                });
                break;

            case TAG:
                this.setState({
                    query: '',
                    searchType: TAG
                });
                break;
        }
    };

    private handleTagClick = (tag: Tag) => {
        this.props.setActiveTag(tag);
        this.props.searchDomainObjectsByTag(DomainType.BUSINESS, tag);
    };

    private renderSearchTags = () => {
        if (!this.props.tags || this.props.tags.length === 0) {
            return <React.Fragment />;
        }
        return this.props.tags.map(tag => {
            return (
                <Button
                    key={tag.id}
                    className="close-button m-1"
                    variant="info"
                    size="sm"
                    onClick={() => {
                        this.handleTagClick(tag);
                    }}
                >
                    {tag.name}
                </Button>
            );
        });
    };

    private renderModifyTagButton = () => {
        if (!this.props.activeTag) {
            return <React.Fragment />;
        }

        return (
            <Button
                key="modify"
                className="close-button m-1"
                variant="secondary"
                size="sm"
                onClick={() => {
                    this.props.editTag(this.props.activeTag as Tag);
                }}
            >
                Modify Tag
            </Button>
        );
    };

    private renderBulkTagButton = () => {
        if (!this.props.activeTag) {
            return <React.Fragment />;
        }

        return (
            <Button
                key="bulk"
                className="close-button m-1"
                variant="secondary"
                size="sm"
                onClick={() => {
                    this.props.bulkTag(this.props.activeTag as Tag);
                }}
            >
                Bulk Tag
            </Button>
        );
    };

    render() {
        let placeholderText = PlaceholderText.TAG;

        if (this.state.searchType === DomainType.BUSINESS) {
            placeholderText = PlaceholderText.BUSINESS;
        } else if (this.state.searchType === DomainType.BUSINESS_GROUP) {
            placeholderText = PlaceholderText.BUSINESS_GROUP;
        }

        return (
            <div>
                <SearchBarForm className="search-bar" onSubmit={this.handleSubmit} autoComplete="off">
                    <Row>
                        <Col sm="5">
                            <Form.Group controlId="query">
                                <Form.Control
                                    as="select"
                                    type="searchType"
                                    onChange={this.handleSearchTypeChange}
                                    value={this.state.searchType}
                                >
                                    <option value={DomainType.BUSINESS}>Businesses</option>
                                    <option value={DomainType.BUSINESS_GROUP}>Business Groups</option>
                                    <option value={TAG}>Tags</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="query">
                                <Form.Control
                                    autoFocus
                                    onChange={this.handleChange as any}
                                    type="text"
                                    value={this.state.query}
                                    placeholder={placeholderText}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button variant="primary" type="submit" disabled={!this.validateForm()}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </SearchBarForm>

                {this.renderSearchTags()}
                <div>
                    {this.renderModifyTagButton()}
                    {this.renderBulkTagButton()}
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state: State) => ({
    activeTag: getActiveTag(state),
    query: getQuery(state),
    searchType: getSearchType(state),
    tags: getTags(state)
});

export const mapDispatchToProps = (dispatch: Function) => ({
    bulkTag: (tag: Tag) => dispatch(navigationActions.bulkTag(tag)),
    editTag: (tag: Tag) => dispatch(navigationActions.editTag(tag)),
    searchDomainObjects: (domainType: DomainType, keyword: string) =>
        dispatch(apiActions.searchDomainObjects(domainType, keyword)),
    searchDomainObjectsByTag: (domainType: DomainType, tag: Tag) =>
        dispatch(apiActions.searchDomainObjectsByTag(domainType, tag)),
    searchTags: (tagNamePrefix: string) => dispatch(apiActions.searchTags(tagNamePrefix)),
    setActivePageNumber: (activePageNumber: number) =>
        dispatch(searchActions.setActivePageNumber(activePageNumber)),
    setActiveTag: (tag: Tag) => dispatch(tagActions.setActiveTag(tag))
});

export const SearchBar = connect(mapStateToProps, mapDispatchToProps)(SearchBarComponent);
