import * as React from 'react';
import { connect } from 'react-redux';

import { ActionTaken } from 'src/utils/types';

import { TagPage } from './styles';
import { TagForm } from './TagForm';

export class CreateComponent extends React.Component {
    public constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <TagPage>
                <h1>Create Tag</h1>
                <TagForm onSubmitAction={ActionTaken.CREATED} />
            </TagPage>
        );
    }
}

export const Create = connect()(CreateComponent);
