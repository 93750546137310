/* eslint @typescript-eslint/explicit-function-return-type: 0 */

import { createAction } from 'typesafe-actions';

import { DomainType, Tag } from 'src/utils/types';

export const addTag = createAction('API_ADD_TAG', resolve => (tag: Tag) => resolve({ tag }));
export const updateTag = createAction('API_UPDATE_TAG', resolve => (tag: Tag) => resolve({ tag }));
export const deleteTag = createAction('API_DELETE_TAG', resolve => (tag: Tag) => resolve({ tag }));

export const addDomainObjectToTag = createAction(
    'API_ADD_DOMAIN_OBJECT_TO_TAG',
    resolve => (tag: Tag, domainType: DomainType, domainObjectId: number) =>
        resolve({ tag, domainType, domainObjectId })
);
export const addDomainObjectToTagBulk = createAction(
    'API_ADD_DOMAIN_OBJECT_TO_TAG_BULK',
    resolve => (tag: Tag, domainType: DomainType, file: File) => resolve({ tag, domainType, file })
);
export const deleteDomainObjectToTag = createAction(
    'API_DELETE_DOMAIN_OBJECT_TO_TAG',
    resolve => (tag: Tag, domainType: DomainType, domainObjectId: number) =>
        resolve({ tag, domainType, domainObjectId })
);
export const deleteDomainObjectToTagBulk = createAction(
    'API_DELETE_DOMAIN_OBJECT_TO_TAG_BULK',
    resolve => (tag: Tag, domainType: DomainType, file: File) => resolve({ tag, domainType, file })
);

export const searchDomainObjects = createAction(
    'API_SEARCH_DOMAIN_OBJECTS',
    resolve => (domainType: DomainType, keyword: string) => resolve({ domainType, keyword })
);
export const searchDomainObjectsByTag = createAction(
    'API_SEARCH_DOMAIN_OBJECTS_BY_TAG',
    resolve => (domainType: DomainType, tag: Tag) => resolve({ domainType, tag })
);
export const searchTags = createAction('API_SEARCH_TAGS', resolve => (tagNamePrefix: string) =>
    resolve({ tagNamePrefix })
);

export const error = createAction('API_ERROR', resolve => (error: Error) => resolve({ error }));
