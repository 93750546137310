import styled from 'styled-components';

export const SearchBarForm = styled.form`
    width: 30%;

    .col-sm-5 {
        margin: 0;
        padding: 0;
    }
    .row {
        flex-wrap: nowrap;
        margin: 0;
    }
`;

export const SearchPage = styled.div`
    padding: 2em 3em;
    padding-bottom: 0em;

    h1 {
        font-size: 24pt;
        padding-bottom: 1em;
    }
`;

export const BusinessTableWrapper = styled.div`
    padding: 2em 3em;

    h1 {
        font-size: 24pt;
        padding-bottom: 1em;
    }
`;
