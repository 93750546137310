import * as React from 'react';
import { Provider } from 'react-redux';

import { Routes } from 'src/components/routes/Routes';
import { Store } from 'src/redux/store';

import { AppHeader, GlobalStyle } from './styles';

export class App extends React.Component {
    public render() {
        return (
            <>
                <GlobalStyle />
                <Provider store={Store}>
                    <AppHeader>Tagr</AppHeader>
                    <Routes />
                </Provider>
            </>
        );
    }
}
