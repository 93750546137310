import { getType } from 'typesafe-actions';

import { ActionTaken } from 'src/utils/types';

import * as tagActions from './actions';
import { TagActions, TagState } from './types';

const INITIAL_STATE: TagState = {
    activeTag: null,
    actionTaken: ActionTaken.MODIFIED,
    errorMessage: null
};

export const tagReducer = (state: TagState = INITIAL_STATE, action: TagActions): TagState => {
    switch (action.type) {
        case getType(tagActions.setActiveTag):
            return {
                ...state,
                activeTag: action.payload.tag,
                errorMessage: action.payload.errorMessage
            };
        case getType(tagActions.setActionTaken):
            return {
                ...state,
                actionTaken: action.payload.actionTaken
            };
        default:
            return state;
    }
};
