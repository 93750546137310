import * as React from 'react';
import { connect } from 'react-redux';

import { getActiveDomainObject } from 'src/redux/domainObject/selectors';
import { State } from 'src/redux/state.types';
import { DomainObject } from 'src/utils/types';

import { DomainObjectTags } from './DomainObjectTags';

interface DomainObjectViewStateProps {
    domainObject: DomainObject | null;
}

export type DomainObjectViewComponentProps = DomainObjectViewStateProps;

export class DomainObjectViewComponent extends React.Component<DomainObjectViewComponentProps> {
    public constructor(props: DomainObjectViewComponentProps) {
        super(props);
    }

    render() {
        if (!this.props.domainObject) {
            return <h2>Please Select a Business from the Search</h2>;
        }

        return <DomainObjectTags domainObject={this.props.domainObject} />;
    }
}

export const mapStateToProps: (state: State) => DomainObjectViewStateProps = (state: State) => ({
    domainObject: getActiveDomainObject(state)
});

export const DomainObjectView = connect(mapStateToProps)(DomainObjectViewComponent);
