import * as React from 'react';
import { connect } from 'react-redux';

import * as searchActions from 'src/redux/search/actions';
import { getSearchStatus } from 'src/redux/search/selectors';
import { SearchStatus } from 'src/redux/search/types';
import { State } from 'src/redux/state.types';

import { Waiting } from '../waiting/Waiting';
import { BusinessTable } from './BusinessTable';
import { SearchBar } from './SearchBar';
import { SearchPage } from './styles';

interface SearchStateProps {
    searchStatus: SearchStatus;
}

interface SearchDispatchProps {
    clearSearch: () => void;
}

export type SearchComponentProps = SearchStateProps & SearchDispatchProps;

export class SearchComponent extends React.Component<SearchComponentProps> {
    public constructor(props: SearchComponentProps) {
        super(props);
    }

    componentDidMount() {
        this.props.clearSearch();
    }

    render() {
        if (this.props.searchStatus === SearchStatus.IN_PROGRESS) {
            return <Waiting />;
        }

        return (
            <SearchPage>
                <h1>Search</h1>
                <SearchBar clearSearch={this.props.clearSearch} />
                <BusinessTable resultsPerPage={10} />
            </SearchPage>
        );
    }
}

export const mapStateToProps = (state: State) => ({
    searchStatus: getSearchStatus(state)
});

export const mapDispatchToProps = (dispatch: Function) => ({
    clearSearch: () => dispatch(searchActions.clearSearch())
});

export const Search = connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
