import { ActionType } from 'typesafe-actions';

import { ActionTaken, Tag } from 'src/utils/types';

import * as tagActions from './actions';

export enum ErrorMessage {
    TAG_ALREADY_EXISTS = 'Tag already exists',
    TAG_IN_USE = 'Tag is in use'
}

export interface TagState {
    activeTag: Tag | null;
    actionTaken: ActionTaken;
    errorMessage: ErrorMessage | null;
}

export type TagActions = ActionType<typeof tagActions>;
