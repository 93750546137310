import Cookies from 'js-cookie';
import { SagaIterator } from 'redux-saga';
import { all, call } from 'redux-saga/effects';

import { config } from 'src/config';
import { Business, DomainObject, DomainType, HttpVerb, Tag } from 'src/utils/types';

import { EDGE_TOKEN_NAME, NOT_AUTHENTICATED_MESSAGE, RESOURCE_CONFLICT } from './index';

export function _getTagrServerName(): string {
    if (config.TAGR_SERVER_NAME_OVERRIDE) {
        return config.TAGR_SERVER_NAME_OVERRIDE;
    }

    let tagrServerName = `${config['WEBAPP_CONFIG']['API_SCHEME']}://`;

    if (
        config['WEBAPP_CONFIG']['API_NAMESPACE'] &&
        !['default', 'awesomestartup'].includes(config['WEBAPP_CONFIG']['API_NAMESPACE'])
    ) {
        tagrServerName += `${config['WEBAPP_CONFIG']['API_NAMESPACE']}.`;
    }

    if (config['WEBAPP_CONFIG']['EDGE_SUBDOMAIN']) {
        tagrServerName += `${config['WEBAPP_CONFIG']['EDGE_SUBDOMAIN']}`;
    }

    if (config['WEBAPP_CONFIG']['API_DOMAIN']) {
        tagrServerName += `.${config['WEBAPP_CONFIG']['API_DOMAIN']}`;
    }

    if (config['WEBAPP_CONFIG']['API_PORT']) {
        tagrServerName += `:${config['WEBAPP_CONFIG']['API_PORT']}`;
    }

    return tagrServerName;
}

export function* checkReady(): SagaIterator<void> {
    yield call(_tagrFetch, HttpVerb.GET, 'ready', 'application/json;charset=utf-8');
}

export const _getUserName = (): string => {
    const userName = window.localStorage.getItem('email');

    if (!userName) {
        throw new Error(NOT_AUTHENTICATED_MESSAGE);
    }

    return userName;
};

export function* _tagrFetch(
    verb: HttpVerb,
    urlPath: string,
    contentType: string | null,
    body?: string | FormData
): any {
    const tagrServerName = _getTagrServerName();
    const tagrServiceNameUrlPart = config['WEBAPP_CONFIG']['API_SERVICE_NAME']
        ? `/${config['WEBAPP_CONFIG']['API_SERVICE_NAME']}`
        : '';
    const url = `${tagrServerName}${tagrServiceNameUrlPart}/${urlPath}`;
    const response: Response = yield call(_fetch, verb, url, contentType, body);

    if (Math.floor(response.status / 100) === 2) {
        if (response.status === 204) {
            return;
        }

        const json = yield call([response, response.json]);
        return json;
    }

    if (response.status === 409) {
        throw new Error(RESOURCE_CONFLICT);
    }

    console.log(response);

    if (response.status === 401) {
        throw new Error(NOT_AUTHENTICATED_MESSAGE);
    }

    throw new Error(`Response status ${response.status}`);
}

export function* _fetch(verb: HttpVerb, url: string, contentType: string | null, body?: string | FormData): any {
    const headers: { [key: string]: string } = {};

    if (contentType) {
        headers['Content-Type'] = contentType;
    }

    if (process.env.REACT_APP_ENV === 'local') {
        return yield call(fetch, url, {
            body,
            headers,
            method: verb
        });
    }

    const edgeToken = Cookies.get(EDGE_TOKEN_NAME);

    if (!edgeToken) {
        throw new Error(NOT_AUTHENTICATED_MESSAGE);
    }

    headers['fs-edge-csrf-token'] = edgeToken;

    return yield call(fetch, url, {
        body,
        credentials: 'include',
        headers,
        method: verb,
        mode: 'cors'
    });
}

// Tags

export function* addTag(name: string, description: string): SagaIterator<Tag> {
    const userName = _getUserName();
    return yield call(
        _tagrFetch,
        HttpVerb.POST,
        'api/tag',
        'application/json;charset=utf-8',
        JSON.stringify({
            name,
            description,
            userName
        })
    );
}

export function* getTag(name: string): SagaIterator<Tag> {
    return yield call(_tagrFetch, HttpVerb.GET, `api/tag?name=${name}`, 'application/json;charset=utf-8');
}

export function* updateTag(name: string, description: string, id: number): SagaIterator<Tag> {
    const userName = _getUserName();
    return yield call(
        _tagrFetch,
        HttpVerb.PUT,
        `api/tag`,
        'application/json;charset=utf-8',
        JSON.stringify({
            id,
            name,
            description,
            userName
        })
    );
}

export function* deleteTag(id: number): SagaIterator<void> {
    const userName = _getUserName();
    yield call(
        _tagrFetch,
        HttpVerb.DELETE,
        'api/tag/',
        'application/json;charset=utf-8',
        JSON.stringify({ id, userName })
    );
}

export function* searchTags(tagNamePrefix: string): SagaIterator<Tag[]> {
    return yield call(
        _tagrFetch,
        HttpVerb.GET,
        `api/tag/search?tagNamePrefix=${tagNamePrefix}`,
        'application/json;charset=utf-8'
    );
}

// DomaintType

export function* addDomainObjectToTag(
    domainType: DomainType,
    domainObjectId: number,
    tagId: number
): SagaIterator<Tag[]> {
    const userName = _getUserName();
    return yield call(
        _tagrFetch,
        HttpVerb.POST,
        `api/domain_type/${domainType}`,
        'application/json;charset=utf-8',
        JSON.stringify({
            domainObjectId,
            tagId,
            userName
        })
    );
}

export function* addDomainObjectToTagBulk(domainType: DomainType, file: File, tagId: number): SagaIterator<Tag[]> {
    const userName = _getUserName();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('tagId', `${tagId}`);
    formData.append('userName', userName);
    return yield call(_tagrFetch, HttpVerb.POST, `api/domain_type/${domainType}/bulk`, null, formData);
}

export function* deleteDomainObjectToTag(
    domainType: DomainType,
    domainObjectId: number,
    tagId: number
): SagaIterator<Tag[]> {
    const userName = _getUserName();
    return yield call(
        _tagrFetch,
        HttpVerb.DELETE,
        `api/domain_type/${domainType}`,
        'application/json;charset=utf-8',
        JSON.stringify({
            domainObjectId,
            tagId,
            userName
        })
    );
}

export function* deleteDomainObjectToTagBulk(
    domainType: DomainType,
    file: File,
    tagId: number
): SagaIterator<Tag[]> {
    const userName = _getUserName();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('tagId', `${tagId}`);
    formData.append('userName', userName);
    return yield call(_tagrFetch, HttpVerb.DELETE, `api/domain_type/${domainType}/bulk`, null, formData);
}

export function* getDomainObjectTags(domainType: DomainType, domainObjectId: number): SagaIterator<Tag[]> {
    const apiResponse: Response = yield call(
        _tagrFetch,
        HttpVerb.GET,
        `api/domain_type/${domainType}?domainObjectId=${domainObjectId}`,
        'application/json;charset=utf-8'
    );

    if (Array.isArray(apiResponse)) {
        return apiResponse.map((tagObject: any) => _formatIntoTag(tagObject));
    }

    return [];
}

export const _formatIntoTag = (tagObject: any): Tag => ({
    description: tagObject.description,
    id: tagObject.id,
    name: tagObject.name
});

type BusinessObject = {
    business_group_id: number;
    business_group_name: string;
    business_group_shortname: string;
    business_group_uid: string;
    business_id: number;
    business_uid: string;
    deactivated_on: string | null;
    delinquent_thirty_day: string | null;
    is_demo: boolean;
    is_free_plan: boolean;
    latitude: string;
    longitude: string;
    phone: string | null;
    salesforce_lead: string;
    shortname: string;
    software_id: string;
    text_code: string | null;
};

export function* searchDomainObjects(domainType: DomainType, keyword: string): SagaIterator<DomainObject[]> {
    const apiResponse: any[] = yield call(
        _tagrFetch,
        HttpVerb.GET,
        `api/domain_type/${domainType}/search?searchTerm=${keyword}`,
        'application/json;charset=utf-8'
    );

    return yield call(_processDomainObjects, domainType, apiResponse);
}

export function* searchDomainObjectsByTag(domainType: DomainType, tagId: number): SagaIterator<DomainObject[]> {
    const apiResponse: any[] = yield call(
        _tagrFetch,
        HttpVerb.GET,
        `api/domain_type/${domainType}/searchByTag?tagId=${tagId}`,
        'application/json;charset=utf-8'
    );

    return yield call(_processDomainObjects, domainType, apiResponse);
}

export function* _processDomainObjects(domainType: DomainType, apiResponse: any[]): SagaIterator<DomainObject[]> {
    if (!apiResponse) {
        return [];
    }

    if (domainType === DomainType.BUSINESS || domainType === DomainType.BUSINESS_GROUP) {
        const businesses: Business[] = yield all(
            apiResponse.map((businessObject: BusinessObject) => call(_formatIntoBusiness, businessObject))
        );
        return businesses;
    }

    return [];
}

export const _formatIntoBusiness = (businessObject: BusinessObject): Business => ({
    domainType: DomainType.BUSINESS,
    id: businessObject.business_id,
    primaryKey: businessObject.software_id,
    tags: null,
    businessName: businessObject.shortname,
    businessGroup: {
        domainType: DomainType.BUSINESS_GROUP,
        id: businessObject.business_group_id,
        primaryKey: businessObject.business_group_name,
        tags: null
    }
});
