import { ActionType } from 'typesafe-actions';

import { DomainObject, DomainType, Tag, TAG } from 'src/utils/types';

import * as searchActions from './actions';

export enum SearchStatus {
    CLEARED = 'CLEARED',
    DONE = 'DONE',
    IN_PROGRESS = 'IN_PROGRESS'
}

export type SearchType = DomainType | typeof TAG;

export interface SearchState {
    activePageNumber: number;
    domainObjects: DomainObject[];
    query: string;
    searchStatus: SearchStatus;
    searchType: SearchType;
    tags: Tag[];
}

export type SearchActions = ActionType<typeof searchActions>;
