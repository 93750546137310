import { State } from 'src/redux/state.types';
import { ActionTaken, Tag } from 'src/utils/types';

import { ErrorMessage } from './types';

export const getActiveTag = (state: State): Tag | null => {
    return state.tag.activeTag;
};

export const getActionTaken = (state: State): ActionTaken => {
    return state.tag.actionTaken;
};

export const getErrorMessage = (state: State): ErrorMessage | null => {
    return state.tag.errorMessage;
};
