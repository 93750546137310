import * as React from 'react';
import { Badge, Pagination, Table } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as navigationActions from 'src/redux/navigation/actions';
import * as searchActions from 'src/redux/search/actions';
import { getActivePageNumber, getDomainObjects } from 'src/redux/search/selectors';
import { State } from 'src/redux/state.types';
import { Business, DomainObject, Tag } from 'src/utils/types';

import { BusinessTableWrapper } from './styles';

interface BusinessTableProp {
    resultsPerPage: number;
}

interface BusinessTableStateProps {
    activePageNumber: number;
    domainObjects: DomainObject[];
}

interface BusinessTableDispatchProps {
    goToDomainObjectView: (domainObject: DomainObject) => void;
    goToEditTag: (tag: Tag) => void;
    setActivePageNumber: (activePageNumber: number) => void;
}

export type BusinessTableComponentProps = BusinessTableStateProps & BusinessTableProp & BusinessTableDispatchProps;

export class BusinessTableComponent extends React.Component<BusinessTableComponentProps> {
    public constructor(props: BusinessTableComponentProps) {
        super(props);
    }

    private renderTags = (business: Business) => {
        if (!business.tags) {
            return <small className="text-muted">Loading...</small>;
        }
        if (business.tags.length === 0) {
            return <small className="text-muted">No tags</small>;
        }

        return business.tags.map(tag => {
            return (
                <Badge key={tag.name} variant="dark" onClick={() => this.props.goToEditTag(tag)}>
                    {tag.name}
                </Badge>
            );
        });
    };

    private paginate = (): JSX.Element[] => {
        const items = [];
        const NUM_PAGES = Math.ceil(this.props.domainObjects.length / this.props.resultsPerPage);
        for (let pageNumber = 1; pageNumber <= NUM_PAGES; pageNumber += 1) {
            items.push(
                <Pagination.Item
                    key={`page_${pageNumber}`}
                    active={pageNumber === this.props.activePageNumber}
                    onClick={() => this.props.setActivePageNumber(pageNumber)}
                >
                    {pageNumber}
                </Pagination.Item>
            );
        }
        return items;
    };

    private getPageStartIndex = () => {
        return this.props.activePageNumber * this.props.resultsPerPage - this.props.resultsPerPage;
    };

    private getPageEndIndex = () => {
        return this.props.activePageNumber * this.props.resultsPerPage;
    };

    private renderSlice = () => {
        const slice: DomainObject[] = this.props.domainObjects.slice(
            this.getPageStartIndex(),
            this.getPageEndIndex()
        );

        return slice.length === 0 ? <></> : slice.map(business => this.createTableEntry(business as Business));
    };

    private createTableEntry = (business: Business): JSX.Element => {
        return (
            <tr key={business.id}>
                <td className="link-text" onClick={() => this.props.goToDomainObjectView(business)}>
                    {business.primaryKey}
                </td>
                <td className="link-text" onClick={() => this.props.goToDomainObjectView(business.businessGroup)}>
                    {business.businessGroup.primaryKey}
                </td>
                <td>{business.id}</td>
                <td>{this.renderTags(business)}</td>
            </tr>
        );
    };

    render() {
        if (!this.props.domainObjects.length) {
            return <></>;
        }
        return (
            <BusinessTableWrapper>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Business</th>
                            <th>Business Group</th>
                            <th>Business ID</th>
                            <th>Tags</th>
                        </tr>
                    </thead>
                    <tbody>{this.renderSlice()}</tbody>
                </Table>
                <Pagination className="pagination" size="sm">
                    {this.paginate()}
                </Pagination>
            </BusinessTableWrapper>
        );
    }
}

export const mapStateToProps: (state: State) => BusinessTableStateProps = (state: State) => ({
    activePageNumber: getActivePageNumber(state),
    domainObjects: getDomainObjects(state)
});

export const mapDispatchToProps = (dispatch: Function) => ({
    goToDomainObjectView: (domainObject: DomainObject) =>
        dispatch(navigationActions.domainObjectView(domainObject)),
    goToEditTag: (tag: Tag) => dispatch(navigationActions.editTag(tag)),
    setActivePageNumber: (activePageNumber: number) => dispatch(searchActions.setActivePageNumber(activePageNumber))
});

export const BusinessTable = connect(mapStateToProps, mapDispatchToProps)(BusinessTableComponent);
