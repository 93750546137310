import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { config } from 'src/config';
import * as apiActions from 'src/redux/api/actions';
import * as domainObjectActions from 'src/redux/domainObject/actions';
import { getActiveDomainObject } from 'src/redux/domainObject/selectors';
import * as loginActions from 'src/redux/login/actions';
import * as navigationActions from 'src/redux/navigation/actions';
import * as searchActions from 'src/redux/search/actions';
import { ErrorMessage } from 'src/redux/tag/types';
import {
    addDomainObjectToTag,
    addDomainObjectToTagBulk,
    addTag,
    checkReady,
    deleteDomainObjectToTag,
    deleteDomainObjectToTagBulk,
    deleteTag,
    getDomainObjectTags,
    getTag,
    searchDomainObjects,
    searchDomainObjectsByTag,
    searchTags,
    updateTag
} from 'src/sagas/api/tagr';
import { ActionTaken, DomainObject, DomainType, HttpVerb, Tag } from 'src/utils/types';

export const EDGE_TOKEN_NAME = 'edge_session_csrf_token';
export const NOT_AUTHENTICATED_MESSAGE = 'Not Authenticated';
// This can happen if we try to create a tag that already exists
// or we try to delete a tag that is linked to business objects
export const RESOURCE_CONFLICT = 'Resource Exists';

export function* watcher(): SagaIterator<void> {
    yield call(_setWebappConfig);
    yield call(_checkAuthenticated);

    yield all([
        takeLatest(loginActions.login, _login),
        takeLatest(apiActions.addTag, _addTag),
        takeLatest(apiActions.deleteTag, _deleteTag),
        takeLatest(apiActions.updateTag, _updateTag),
        takeLatest(apiActions.searchTags, _searchTags),
        takeLatest(apiActions.addDomainObjectToTag, _addDomainObjectToTag),
        takeLatest(apiActions.addDomainObjectToTagBulk, _addDomainObjectToTagBulk),
        takeLatest(apiActions.deleteDomainObjectToTag, _deleteDomainObjectToTag),
        takeLatest(apiActions.deleteDomainObjectToTagBulk, _deleteDomainObjectToTagBulk),
        takeLatest(apiActions.searchDomainObjects, _searchDomainObjects),
        takeLatest(apiActions.searchDomainObjectsByTag, _searchDomainObjectsByTag)
    ]);
}

export function* _setWebappConfig(): SagaIterator<void> {
    try {
        let tagrServerNameOverride = '';

        if (config.TAGR_SERVER_NAME_OVERRIDE) {
            tagrServerNameOverride = config.TAGR_SERVER_NAME_OVERRIDE;
        }

        // Normally the tagr host override is empty and we retrieve the webapp config
        // from the same host that we used to access the client.  However, the tagr
        // host override allows the server to have a different fqdn from the client.
        // A good example is when we are running locally.
        // You can also use this if you want to run the client locally but point to
        // a cluster in AWS.
        const response = yield call(fetch, `${tagrServerNameOverride}/api/webapp_config`, {
            method: HttpVerb.GET
        });

        const json = yield call([response, response.json]);
        config['WEBAPP_CONFIG']['API_DOMAIN'] = json.api_domain;
        config['WEBAPP_CONFIG']['API_NAMESPACE'] = json.api_namespace;
        config['WEBAPP_CONFIG']['API_PORT'] = json.api_port;
        config['WEBAPP_CONFIG']['API_SCHEME'] = json.api_scheme;
        config['WEBAPP_CONFIG']['API_SERVICE_NAME'] = json.api_service_name;
        config['WEBAPP_CONFIG']['AUTH_SUBDOMAIN'] = json.auth_subdomain;
        config['WEBAPP_CONFIG']['EDGE_SUBDOMAIN'] = json.edge_subdomain;
    } catch (error) {
        yield put(apiActions.error(error));
    }
}

export function* _checkAuthenticated(): SagaIterator<void> {
    const edgeToken = Cookies.get(EDGE_TOKEN_NAME);
    const userName = window.localStorage.getItem('email');

    if (!edgeToken || !userName) {
        return;
    }

    try {
        yield call(checkReady);
        yield put(loginActions.authenticated());
    } catch (error) {
        if (error.message !== NOT_AUTHENTICATED_MESSAGE) {
            yield put(apiActions.error(error));
        }
    }
}

export function _getAuthServerName(): string {
    let authServerName = `${config['WEBAPP_CONFIG']['API_SCHEME']}://`;

    if (
        config['WEBAPP_CONFIG']['API_NAMESPACE'] &&
        !['default', 'awesomestartup'].includes(config['WEBAPP_CONFIG']['API_NAMESPACE'])
    ) {
        authServerName += `${config['WEBAPP_CONFIG']['API_NAMESPACE']}.`;
    }

    authServerName += config['WEBAPP_CONFIG']['AUTH_SUBDOMAIN'];

    if (config['WEBAPP_CONFIG']['API_DOMAIN']) {
        authServerName += `.${config['WEBAPP_CONFIG']['API_DOMAIN']}`;
    }

    if (config['WEBAPP_CONFIG']['API_PORT']) {
        authServerName += `:${config['WEBAPP_CONFIG']['API_PORT']}`;
    }

    return authServerName;
}

export function* _login(action: ActionType<typeof loginActions.login>): SagaIterator<void> {
    const authServerName = _getAuthServerName();
    const base64LoginDetails = btoa(`${action.payload.email}:${action.payload.password}`);

    try {
        const response: Response = yield call(fetch, `${authServerName}/api/unified/edge/auth/login`, {
            body: JSON.stringify({}),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                Authorization: `Basic ${base64LoginDetails}`
            },
            method: HttpVerb.POST,
            mode: 'cors'
        });

        if (response.status !== 201) {
            yield put(loginActions.failure());
            return;
        }

        window.localStorage.setItem('email', action.payload.email);

        if (window.location.hostname === 'tagr-server.fslocal') {
            // When running locally, spoof a cookie so we
            // skip authentication.
            Cookies.set(EDGE_TOKEN_NAME, EDGE_TOKEN_NAME);
        }

        yield put(loginActions.authenticated());
    } catch (error) {
        yield put(apiActions.error(error));
    }
}

export function* _addTag(action: ActionType<typeof apiActions.addTag>): SagaIterator<void> {
    try {
        yield put(navigationActions.waiting());
        yield call(addTag, action.payload.tag.name, action.payload.tag.description);
        yield put(navigationActions.confirmation(ActionTaken.CREATED, action.payload.tag));
    } catch (error) {
        if (error.message === RESOURCE_CONFLICT) {
            try {
                const tag: Tag = yield call(getTag, action.payload.tag.name);
                yield put(navigationActions.editTag(tag, ErrorMessage.TAG_ALREADY_EXISTS));
            } catch (error) {
                yield call(_handleError, error);
            }
        } else {
            yield call(_handleError, error);
        }
    }
}

export function* _updateTag(action: ActionType<typeof apiActions.updateTag>): SagaIterator<void> {
    try {
        yield put(navigationActions.waiting());
        const tag = action.payload.tag;
        yield call(updateTag, tag.name, tag.description, tag.id);
        yield put(navigationActions.confirmation(ActionTaken.MODIFIED, tag));
    } catch (error) {
        yield call(_handleError, error);
    }
}

export function* _deleteTag(action: ActionType<typeof apiActions.deleteTag>): SagaIterator<void> {
    const tag = action.payload.tag;

    try {
        yield put(navigationActions.waiting());
        yield call(deleteTag, tag.id);
        yield put(navigationActions.confirmation(ActionTaken.DELETED, tag));
    } catch (error) {
        if (error.message === RESOURCE_CONFLICT) {
            yield put(navigationActions.editTag(tag, ErrorMessage.TAG_IN_USE));
        } else {
            yield call(_handleError, error);
        }
    }
}

export function* _searchTags(action: ActionType<typeof apiActions.searchTags>): SagaIterator<void> {
    try {
        const tags: Tag[] = yield call(searchTags, action.payload.tagNamePrefix);
        yield put(searchActions.setTags(tags));
    } catch (error) {
        yield call(_handleError, error);
    }
}

export function* _addDomainObjectToTag(
    action: ActionType<typeof apiActions.addDomainObjectToTag>
): SagaIterator<void> {
    try {
        const tags: Tag[] = yield call(
            addDomainObjectToTag,
            action.payload.domainType,
            action.payload.domainObjectId,
            action.payload.tag.id
        );
        yield call(_setActiveDomainObject, action.payload.domainType, action.payload.domainObjectId, tags);
    } catch (error) {
        yield call(_handleError, error);
    }
}

export function* _addDomainObjectToTagBulk(
    action: ActionType<typeof apiActions.addDomainObjectToTagBulk>
): SagaIterator<void> {
    try {
        yield put(navigationActions.waiting());
        yield call(addDomainObjectToTagBulk, action.payload.domainType, action.payload.file, action.payload.tag.id);
        yield put(navigationActions.confirmation(ActionTaken.BULK_TAG, action.payload.tag));
    } catch (error) {
        yield call(_handleError, error);
    }
}

export function* _deleteDomainObjectToTag(
    action: ActionType<typeof apiActions.deleteDomainObjectToTag>
): SagaIterator<void> {
    try {
        const tags: Tag[] = yield call(
            deleteDomainObjectToTag,
            action.payload.domainType,
            action.payload.domainObjectId,
            action.payload.tag.id
        );
        yield call(_setActiveDomainObject, action.payload.domainType, action.payload.domainObjectId, tags);
    } catch (error) {
        yield call(_handleError, error);
    }
}

export function* _deleteDomainObjectToTagBulk(
    action: ActionType<typeof apiActions.deleteDomainObjectToTagBulk>
): SagaIterator<void> {
    try {
        yield put(navigationActions.waiting());
        yield call(
            deleteDomainObjectToTagBulk,
            action.payload.domainType,
            action.payload.file,
            action.payload.tag.id
        );
        yield put(navigationActions.confirmation(ActionTaken.BULK_UNTAG, action.payload.tag));
    } catch (error) {
        yield call(_handleError, error);
    }
}

export function* _setActiveDomainObject(
    domainType: DomainType,
    domainObjectId: number,
    tags: Tag[]
): SagaIterator<void> {
    const domainObjectCurrent: DomainObject = yield select(getActiveDomainObject);

    if (domainObjectCurrent.domainType === domainType && domainObjectCurrent.id === domainObjectId) {
        const domainObjectUpdated: DomainObject = cloneDeep(domainObjectCurrent);
        domainObjectUpdated.tags = tags;
        yield put(domainObjectActions.setActiveDomainObject(domainObjectUpdated));
    }
}

export function* _searchDomainObjects(
    action: ActionType<typeof apiActions.searchDomainObjects>
): SagaIterator<void> {
    try {
        const domainObjects: DomainObject[] = yield call(
            searchDomainObjects,
            action.payload.domainType,
            action.payload.keyword
        );
        yield put(searchActions.setDomainObjects(domainObjects));
        yield fork(_getTagsForDomainObjects, action.payload.domainType, domainObjects);
    } catch (error) {
        yield call(_handleError, error);
    }
}

export function* _searchDomainObjectsByTag(
    action: ActionType<typeof apiActions.searchDomainObjectsByTag>
): SagaIterator<void> {
    try {
        const domainObjects: DomainObject[] = yield call(
            searchDomainObjectsByTag,
            action.payload.domainType,
            action.payload.tag.id
        );
        yield put(searchActions.setDomainObjects(domainObjects));
        yield fork(_getTagsForDomainObjects, action.payload.domainType, domainObjects);
    } catch (error) {
        yield call(_handleError, error);
    }
}

export function* _getTagsForDomainObjects(
    domainType: DomainType,
    domainObjects: DomainObject[]
): SagaIterator<void> {
    if (![DomainType.BUSINESS, DomainType.BUSINESS_GROUP].includes(domainType)) {
        return;
    }

    for (let i = 0; i < domainObjects.length; i++) {
        const id = domainObjects[i].id;
        const tags: Tag[] = yield call(getDomainObjectTags, DomainType.BUSINESS, id);
        yield put(searchActions.setTagsForDomainObject(DomainType.BUSINESS, id, tags));
    }
}

export function* _handleError(error: Error): SagaIterator<void> {
    if (error.message === NOT_AUTHENTICATED_MESSAGE) {
        yield put(loginActions.logout());
    } else {
        yield put(apiActions.error(error));
    }
}
