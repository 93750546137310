import { createBrowserHistory } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import { DomainObjectView } from 'src/components/domainObject/DomainObjectView';
import { Login } from 'src/components/login/Login';
import { OutOfService } from 'src/components/outOfService/OutOfService';
import { Search } from 'src/components/search/Search';
import { Bulk } from 'src/components/tag/Bulk';
import { Confirmation } from 'src/components/tag/Confirmation';
import { Create } from 'src/components/tag/Create';
import { Edit } from 'src/components/tag/Edit';
import { Waiting } from 'src/components/waiting/Waiting';
import { Path } from 'src/utils/types';

import { NavigationBar } from './NavigationBar';
import { PrivateRoute } from './PrivateRoute';

const history = createBrowserHistory();
export { history };

export class RoutesComponent extends React.Component<{}> {
    public constructor(props: any) {
        super(props);

        if (window.location.pathname !== Path.SEARCH) {
            window.location.pathname = Path.SEARCH;
        }
    }

    render() {
        return (
            <Router history={history}>
                <NavigationBar />

                <Switch>
                    <Route exact path={Path.LOGIN} component={Login} />
                    <Route exact path={Path.OUT_OF_SERVICE} component={OutOfService} />
                    <Route exact path={Path.WAITING} component={Waiting} />

                    <PrivateRoute exact path={Path.SEARCH} component={Search} />
                    <PrivateRoute exact path={Path.BULK_TAG} component={Bulk} />
                    <PrivateRoute exact path={Path.CREATE_TAG} component={Create} />
                    <PrivateRoute exact path={Path.EDIT_TAG} component={Edit} />
                    <PrivateRoute exact path={Path.DOMAIN_VIEW} component={DomainObjectView} />
                    <PrivateRoute exact path={Path.CONFIRMATION} component={Confirmation} />
                </Switch>
            </Router>
        );
    }
}

export const Routes = connect()(RoutesComponent);
