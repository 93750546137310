/* eslint @typescript-eslint/explicit-function-return-type: 0 */

import { createAction } from 'typesafe-actions';

import { DomainObject } from 'src/utils/types';

export const setActiveDomainObject = createAction(
    'DOMAIN_SET_ACTIVE_DOMAIN_OBJECT',
    resolve => (domainObject: DomainObject) => resolve({ domainObject })
);
