/* eslint @typescript-eslint/explicit-function-return-type: 0 */

import { createAction } from 'typesafe-actions';

import { ActionTaken, Tag } from 'src/utils/types';

import { ErrorMessage } from './types';

export const setActiveTag = createAction(
    'TAG_SET_ACTIVE_TAG',
    resolve => (tag: Tag | null, errorMessage: ErrorMessage | null = null) => resolve({ tag, errorMessage })
);
export const setActionTaken = createAction('TAG_SET_ACTION_TAKEN', resolve => (actionTaken: ActionTaken) =>
    resolve({ actionTaken })
);
