import { State } from 'src/redux/state.types';
import { DomainObject, Tag } from 'src/utils/types';

import { SearchStatus, SearchType } from './types';

export const getActivePageNumber = (state: State): number => state.search.activePageNumber;

export const getDomainObjects = (state: State): DomainObject[] => state.search.domainObjects;

export const getQuery = (state: State): string => state.search.query;

export const getSearchStatus = (state: State): SearchStatus => state.search.searchStatus;

export const getSearchType = (state: State): SearchType => state.search.searchType;

export const getTags = (state: State): Tag[] => state.search.tags;
