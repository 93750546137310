import * as React from 'react';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as navigationActions from 'src/redux/navigation/actions';
import { State } from 'src/redux/state.types';
import { getActionTaken, getActiveTag } from 'src/redux/tag/selectors';
import { ActionTaken, Tag } from 'src/utils/types';

import { TagPage } from './styles';

interface ConfirmationStateProps {
    actionTaken: ActionTaken;
    tag: Tag | null;
}

interface ConfirmationDispatchProps {
    goToCreateTag: () => void;
    goToSearch: () => void;
}

export type ConfirmationComponentProps = ConfirmationStateProps & ConfirmationDispatchProps;

export class ConfirmationComponent extends React.Component<ConfirmationComponentProps> {
    public constructor(props: ConfirmationComponentProps) {
        super(props);
    }

    private renderCreateTagButton = () => {
        if (this.props.actionTaken !== ActionTaken.CREATED) {
            return <></>;
        }
        return (
            <Button onClick={this.props.goToCreateTag} className="m-1" size="sm">
                Create another tag
            </Button>
        );
    };

    render() {
        if (!this.props.tag) {
            return <h2>No new changes to apply.</h2>;
        }

        return (
            <TagPage>
                <Col>
                    <Row>
                        <h3>
                            Tag
                            <Badge key={this.props.tag.id} className="m-1" variant="dark">
                                {this.props.tag.name} {}
                            </Badge>
                            {this.props.actionTaken}!
                        </h3>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <div className="end-buttons">
                            {this.renderCreateTagButton()}
                            <Button onClick={this.props.goToSearch} className="m-1" size="sm">
                                Done
                            </Button>
                        </div>
                    </Row>
                </Col>
            </TagPage>
        );
    }
}

export const mapStateToProps: (state: State) => ConfirmationStateProps = (state: State) => ({
    actionTaken: getActionTaken(state),
    tag: getActiveTag(state)
});

export const mapDispatchToProps = (dispatch: Function) => ({
    goToCreateTag: () => dispatch(navigationActions.createTag()),
    goToSearch: () => dispatch(navigationActions.search())
});

export const Confirmation = connect(mapStateToProps, mapDispatchToProps)(ConfirmationComponent);
