import * as React from 'react';

import { OutOfServiceWrapper } from './styles';

export class OutOfServiceComponent extends React.Component {
    public constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <OutOfServiceWrapper>
                <h1>We encountered a problem</h1>
            </OutOfServiceWrapper>
        );
    }
}

export const OutOfService = OutOfServiceComponent;
