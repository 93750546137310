import styled from 'styled-components';

export const OutOfServiceWrapper = styled.div`
    padding: 2em;

    h1 {
        font-size: 24pt;
        padding-bottom: 1em;
    }
`;
