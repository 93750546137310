import { ActionType, getType } from 'typesafe-actions';

import * as apiActions from 'src/redux/api/actions';
import { DomainType, TAG } from 'src/utils/types';

import * as searchActions from './actions';
import { SearchActions, SearchState, SearchStatus, SearchType } from './types';

const INITIAL_STATE: SearchState = {
    activePageNumber: 1,
    domainObjects: [],
    query: '',
    searchStatus: SearchStatus.CLEARED,
    searchType: DomainType.BUSINESS,
    tags: []
};

export const searchReducer = (
    state: SearchState = INITIAL_STATE,
    action: SearchActions | ActionType<typeof apiActions>
): SearchState => {
    switch (action.type) {
        case getType(apiActions.searchDomainObjects):
        case getType(apiActions.searchDomainObjectsByTag):
        case getType(apiActions.searchTags): {
            let query = '';
            let searchType: SearchType = TAG;

            if (action.type === getType(apiActions.searchDomainObjects)) {
                query = action.payload.keyword;
                searchType = action.payload.domainType;
            } else if (action.type === getType(apiActions.searchTags)) {
                query = action.payload.tagNamePrefix;
            }

            return {
                ...state,
                query,
                searchStatus: SearchStatus.IN_PROGRESS,
                searchType
            };
        }
        case getType(searchActions.setActivePageNumber):
            return {
                ...state,
                activePageNumber: action.payload.activePageNumber
            };
        case getType(searchActions.setDomainObjects):
            return {
                ...state,
                domainObjects: action.payload.domainObjects,
                searchStatus: SearchStatus.DONE
            };
        case getType(searchActions.setTagsForDomainObject):
            for (let i = 0; i < state.domainObjects.length; i++) {
                const domainObject = state.domainObjects[i];

                if (
                    domainObject.domainType === action.payload.domainType &&
                    domainObject.id === action.payload.id
                ) {
                    domainObject.tags = action.payload.tags;

                    return {
                        ...state,
                        domainObjects: [...state.domainObjects]
                    };
                }
            }

            return state;
        case getType(searchActions.setTags):
            return {
                ...state,
                tags: action.payload.tags,
                searchStatus: SearchStatus.DONE
            };
        case getType(searchActions.clearSearch):
            return {
                ...state,
                domainObjects: [],
                query: '',
                searchStatus: SearchStatus.CLEARED,
                searchType: DomainType.BUSINESS,
                tags: []
            };
        default:
            return state;
    }
};
