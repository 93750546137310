import * as React from 'react';

import loadingSpinnerPurpleGif from 'src/assets/gifs/loading-spinner-purple.gif';

import { CenterWrapper } from './styles';

export const Waiting: React.FC = () => (
    <CenterWrapper>
        <img src={loadingSpinnerPurpleGif} alt="" />
    </CenterWrapper>
);
