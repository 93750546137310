export enum HttpVerb {
    GET = 'GET',
    DELETE = 'DELETE',
    POST = 'POST',
    PUT = 'PUT'
}

export enum Path {
    BULK_TAG = '/bulk-tag',
    CREATE_TAG = '/create-tag',
    CONFIRMATION = '/confirmation',
    DOMAIN_VIEW = '/domain',
    EDIT_TAG = '/edit-tag',
    LOGIN = '/login',
    OUT_OF_SERVICE = '/out-of-service',
    SEARCH = '/',
    WAITING = '/waiting'
}

export enum DomainType {
    BUSINESS = 'business',
    BUSINESS_GROUP = 'business-group'
}

export enum ActionTaken {
    BULK_TAG = 'applied in bulk',
    BULK_UNTAG = 'removed in bulk',
    CREATED = 'created',
    MODIFIED = 'modified',
    DELETED = 'deleted'
}

export const TAG = 'tag';

export interface Tag {
    id: number;
    name: string;
    description: string;
}

interface BaseDomainObject {
    domainType: DomainType;
    id: number;
    primaryKey: string; // name of business group, software ID of business, etc.
    tags: Tag[] | null;
}

export type BusinessGroup = BaseDomainObject;

export interface Business extends BaseDomainObject {
    businessName: string;
    businessGroup: BusinessGroup;
}

export type DomainObject = BusinessGroup | Business;

export const getDomainTypeDisplayName = (domainType: DomainType): string => {
    switch (domainType) {
        case DomainType.BUSINESS_GROUP:
            return 'Business Group';
        case DomainType.BUSINESS:
        default:
            return 'Business';
    }
};
