/* eslint @typescript-eslint/explicit-function-return-type: 0 */

import { createAction } from 'typesafe-actions';

import { DomainObject, DomainType, Tag } from 'src/utils/types';

export const setActivePageNumber = createAction(
    'SEARCH_SET_ACTIVE_PAGE_NUMBER',
    resolve => (activePageNumber: number) => resolve({ activePageNumber })
);

export const clearSearch = createAction('SEARCH_CLEAR_SEARCH');

export const setDomainObjects = createAction(
    'SEARCH_SET_DOMAIN_OBJECTS',
    resolve => (domainObjects: DomainObject[]) => resolve({ domainObjects })
);

export const setTagsForDomainObject = createAction(
    'SEARCH_SET_TAGS_FOR_DOMAIN_OBJECT',
    resolve => (domainType: DomainType, id: number, tags: Tag[]) => resolve({ domainType, id, tags })
);

export const setTags = createAction('SEARCH_SET_TAGS', resolve => (tags: Tag[]) => resolve({ tags }));
